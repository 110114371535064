<template>
  <!--
    Add Calendar event
  -->
  <div class="onCallDutyAddEvent">
    <form>
      <div class="form-group">
        <label
          for="roles"
          class="form-label"
        >{{ $t('customerNotification.roles') }}</label>
        <LoadingPlaceholder v-if="loadingRole" />
        <Multiselect
          v-else
          id="roles"
          v-model="selectedRole"
          tag-placeholder="Not found"
          placeholder="Select a Role"
          :options="roles"
          select-label=""
          :multiple="false"
          :taggable="true"
          :hide-selected="true"
          :searchable="false"
          :close-on-select="true"
          :custom-label="roleLabel"
          class="mb-4"
        />
      </div>
      <div class="form-group">
        <label
          for="contacts"
          class="form-label"
        >{{ $t('installationDetailOperationView.contacts') }}</label>
        <LoadingPlaceholder v-if="loadingContacts" />
        <Multiselect
          v-else
          id="contacts"
          v-model="selectedContact"
          tag-placeholder="Not found"
          placeholder="Select a Contact"
          :options="contacts"
          select-label=""
          :multiple="false"
          :taggable="true"
          :hide-selected="true"
          :searchable="true"
          :close-on-select="true"
          :custom-label="customLabel"
          class="mb-4"
        />
      </div>
    </form>
    <div class="form-check mb-3">
      <input
        id="oneDayCheck"
        v-model="oneDay"
        type="checkbox"
        class="form-check-input"
      >
      <label
        class="form-check-label mr-5"
        for="oneDayCheck"
      >One Day</label>
    </div>
    <template v-if="oneDay">
      <div class="form-group">
        <label>{{ 'On' }}</label>
        <date-picker
          v-model="selectedDateFrom"
          :editable="true"
          :clearable="false"
          type="date"
          lang="en"
          format="DD.MM.YYYY"
          value-type="date"
          :first-day-of-week="1"
          class="ml-3"
        />
      </div>
    </template>
    <template v-else>
      <div class="form-group">
        <label>{{ $t('from') }}</label>
        <date-picker
          v-model="selectedDateFrom"
          :editable="true"
          :clearable="false"
          type="date"
          lang="en"
          format="DD.MM.YYYY"
          value-type="date"
          :first-day-of-week="1"
          class="ml-3"
        />
        <label class="ml-4">{{ $t('to') }}</label>
        <date-picker
          v-model="selectedDateTo"
          :editable="true"
          :clearable="false"
          type="date"
          lang="en"
          format="DD.MM.YYYY"
          value-type="date"
          :first-day-of-week="1"
          class="ml-3"
        />
      </div>
    </template>
    <template v-if="oneDay">
      <div
        class="form-check mb-3 mt-4"
      >
        <input
          id="repeatCheck"
          v-model="repeat"
          type="checkbox"
          class="form-check-input"
        >
        <label
          class="form-check-label"
          for="repeatCheck"
        >Repeat every week till end of month</label>
      </div>
    </template>
  </div>
</template>

<script>
import { dateTimeMixin } from '@/mixins/dateTimeMixin.js';
import DatePicker from 'vue2-datepicker';

export default {
  name: 'OnCallDutyAddEvent',
  components: {
    Multiselect: () => import('vue-multiselect'),
    DatePicker
  },
   mixins: [
    dateTimeMixin
  ],
  props: {
    dateRange: {
      type: Object,
      required: true
    },
    roleColors: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      contacts: null,
      selectedContact: null,
      selectedRole: null,
      roles: null,
      loadingRole: true,
      loadingContacts: true,
      oneDay: false,
      repeat: false,
      selectedRepeatDays: null,
      selectedDateFrom: null,
      selectedDateTo: null,
    }
  },
  computed: {
    isOneDay () {
      var a = this.toMoment(this.selectedDateFrom);
      var b = this.toMoment(this.selectedDateTo);
      return b.diff(a, 'days') == 1;
    }
  },
  watch: {
    selectedRole () {
      this.getContacts();
    }
  },
  created () {
    this.getAlturosContactRoles();
    this.selectedDateFrom = this.dateRange.start;
    this.selectedDateTo = this.dateRange.end;
    this.oneDay = this.isOneDay;
  },
   methods: {
     customLabel ({ firstname, surname }) {
      return `${firstname} ${surname}`
    },
    roleLabel ({ name }) {
      return `${name}`
    },
     getAlturosContactRoles () {
      this.loadingRole = true;
      this.axios.get(`/ApplicationSettings/GetApplicationSettingsByKey?key=alturos-contact-role`)
      .then((response) => {
        this.roles = [];
        response.data.forEach((el, index) => {
          this.roles.push({ name: el.value.split(';')[0], color: this.roleColors[index]});
        });

        if(!this.selectedRole) {
          this.selectedRole = this.roles[0];
        }

        this.loadingRole = false;
      });
    },
    getContacts () {
      this.selectedContact = null;
      this.loadingContacts = true;
      var rolesForReq = [ this.selectedRole.name ];

      this.axios.post(`/OnCallDuty/GetOnCallDutyContacts`, rolesForReq)
      .then((response) => {
        this.contacts = response.data;
      }).finally(() => {
        this.loadingContacts = false;
      });
    },
    createEvent () {
      this.$emit('setLoading', true);
      var req = {
        dateFrom: this.selectedDateFrom,
        dateTo: this.selectedDateTo,
        contact: this.selectedContact,
        role: this.selectedRole.name,
        repeat: this.repeat,
        oneDay: this.oneDay,
        color: this.selectedRole.color
      };

      if(!this.oneDay) {
        req.repeat = false;
      }
      else if(!this.isOneDay) {
         this.selectedDateTo.setDate(this.selectedDateFrom.getDate() + 1);
      }

      this.axios.post(`/OnCallDuty/CreateOnCallDuty`, req)
      .then(() => {
        this.$emit('closeAndReload');
      });
    }
  },
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>